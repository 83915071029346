<div class="result-wrapper" *ngIf="poll">
    <table class="result-table">
        <tbody>
            <tr *ngIf="shouldShowHead">
                <th></th>
                <th colspan="2">{{ 'Votes' | translate }}</th>
            </tr>
            <ng-container *ngFor="let row of tableData; let i = index">
                <ng-container *ngIf="['user', 'list'].includes(row.class)">
                    <tr [ngClass]="row.votingOption">
                        <td>
                            <os-icon-container *ngIf="row.value[0].icon" [icon]="row.value[0].icon" [size]="iconSize">
                                {{ row.votingOption | pollKeyVerbose | translate }}
                            </os-icon-container>
                            <span *ngIf="!row.value[0].icon">
                                {{ row.votingOption | pollKeyVerbose | translate }}
                            </span>
                        </td>
                        <td>
                            <div class="result-cell-definition">
                                <span *ngIf="row.value[0].showPercent" [class]="textSizeClass">
                                    {{ getVoteAmount(row.value[0].amount, row) | pollPercentBase : poll }}
                                </span>
                            </div>
                        </td>
                        <td>
                            <div class="result-cell-definition" [class]="textSizeClass">
                                <span>
                                    {{ getVoteAmount(row.value[0].amount, row) | pollParseNumber }}
                                </span>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="!['user', 'list'].includes(row.class)">
                    <tr [class]="row.votingOption">
                        <!-- YNA/Valid etc -->
                        <td>
                            <os-icon-container *ngIf="row.value[0].icon" [icon]="row.value[0].icon" [size]="iconSize">
                                {{ row.votingOption | pollKeyVerbose | translate }}
                            </os-icon-container>
                            <span *ngIf="!row.value[0].icon" [class]="textSizeClass">
                                {{ row.votingOption | pollKeyVerbose | translate }}
                            </span>
                        </td>

                        <!-- Percent numbers -->
                        <td class="result-cell-definition">
                            <span *ngIf="row.value[0].showPercent" [class]="textSizeClass">
                                {{ row.value[0].amount! | pollPercentBase : poll }}
                            </span>
                        </td>

                        <!-- Voices -->
                        <td class="result-cell-definition" [class]="textSizeClass">
                            {{ row.value[0].amount | pollParseNumber }}
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            <tr *ngIf="shouldShowEntitled" class="entitled-users-row">
                <td>{{ 'Entitled users' | translate }}</td>
                <td class="result-cell-definition">
                    {{ poll.entitled_users_at_stop.length | pollPercentBase : poll }}
                </td>
                <td class="result-cell-definition">
                    {{ poll.entitled_users_at_stop.length }}
                </td>
            </tr>
        </tbody>
    </table>

    <!-- Chart -->
    <div *ngIf="shouldShowChart" class="doughnut-chart">
        <os-chart type="doughnut" [data]="chartData"></os-chart>
    </div>
</div>
