<ng-container *osPerms="permission.projectorCanManage">
    <button
        *ngIf="!menuItem && !blendIn"
        mat-mini-fab
        (click)="onClick($event)"
        [disabled]="disabled"
        [ngClass]="{ 'projector-inactive': !isProjected() }"
    >
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
    <button
        *ngIf="!menuItem && blendIn"
        mat-icon-button
        (click)="onClick($event)"
        [disabled]="disabled"
        class="blend-in"
    >
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
    <button
        *ngIf="menuItem"
        mat-menu-item
        (click)="onClick()"
        [disabled]="disabled"
        [ngClass]="{ 'projector-inactive': !isProjected() }"
    >
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
</ng-container>

<ng-template #content>
    <mat-icon>videocam</mat-icon>
    <ng-container *ngIf="menuItem">
        <span #ref><ng-content></ng-content></span>
        <span *ngIf="!ref.innerHTML.trim()">
            {{ 'Project' | translate }}
        </span>
    </ng-container>
</ng-template>
