<div class="custom-table-header flex-spaced">
    <!-- Amount of filters -->
    <div class="filter-count one-line">
        <span>{{ filterCount }}&nbsp;</span>
        <span>{{ 'of' | translate }}</span>
        &nbsp;
        <span>{{ totalCount }}</span>
        <ng-content select=".extra-info-slot"></ng-content>
    </div>

    <!-- Current filters -->
    <div class="current-filters h-scroller" *ngIf="filterService">
        <div *ngFor="let filter of filterService.filterStack">
            <button mat-stroked-button (click)="removeFilterFromStack(filter)">
                <os-icon-container icon="close" class="active-filter">
                    <span class="active-filter">
                        {{ filter.option.label | translate }}
                    </span>
                </os-icon-container>
            </button>
        </div>
    </div>

    <!-- Actions -->
    <div class="action-buttons">
        <!-- Filter button -->
        <button mat-button *ngIf="hasFilters" (click)="filterMenu.opened ? filterMenu.close() : filterMenu.open()">
            <span
                class="upper"
                [matBadge]="filterAmount"
                matBadgeColor="accent"
                [matBadgeHidden]="filterAmount === 0"
                [matBadgeOverlap]="false"
            >
                {{ 'Filter' | translate }}
            </span>
        </button>

        <!-- Sort Button -->
        <button mat-button *ngIf="vp.isMobile && hasSorting" (click)="openSortDropDown()">
            <span
                matBadge="&#8288;"
                matBadgeColor="accent"
                [matBadgeHidden]="!hasSortOptionSelected"
                [matBadgeOverlap]="false"
                matBadgeSize="small"
                class="upper"
            >
                {{ 'Sort' | translate }}
            </span>
        </button>
        <button
            mat-button
            *ngIf="!vp.isMobile && hasSorting"
            [matTooltip]="getSortLabel(sortOption) | translate"
            [matMenuTriggerFor]="menu"
        >
            <span
                matBadge="&#8288;"
                matBadgeColor="accent"
                [matBadgeHidden]="!hasSortOptionSelected"
                [matBadgeOverlap]="false"
                matBadgeSize="small"
                class="upper"
            >
                {{ 'Sort' | translate }}
            </span>
        </button>

        <!-- Search bar -->
        <os-rounded-input
            #searchField
            *ngIf="searchService"
            placeholder="{{ 'Search' | translate }}"
            [size]="'small'"
            [fullWidth]="false"
            [(ngModel)]="searchFieldInput"
        ></os-rounded-input>
    </div>
</div>

<!-- Header for the filter side bar -->
<mat-drawer autoFocus="false" #filterMenu mode="push" position="end">
    <div class="custom-table-header filter-menu-head" (click)="this.filterMenu.toggle()">
        <span>
            <mat-icon>keyboard_arrow_right</mat-icon>
        </span>

        <button mat-button (click)="onClearAllButton($event)" *ngIf="filterAmount">
            <os-icon-container icon="clear">
                <span>{{ 'Clear all filters' | translate }}</span>
            </os-icon-container>
        </button>
    </div>
    <os-filter-menu
        *ngIf="filterService"
        (dismissed)="this.filterMenu.close()"
        [service]="filterService"
    ></os-filter-menu>
</mat-drawer>

<!-- non-mobile sorting menu -->
<mat-menu #menu>
    <div *ngIf="hasSorting">
        <!-- <mat-list-item> -->
        <button mat-menu-item *ngIf="defaultOption" (click)="sortOption = defaultOption">
            <mat-icon>{{ getSortIcon(defaultOption) }}</mat-icon>
            <span>{{ sortService.getSortLabel(defaultOption) | translate }}&emsp;</span>
            <small class="subtitle">{{ '(Default)' | translate }}</small>
        </button>
        <mat-divider *ngIf="defaultOption"></mat-divider>
        <!-- </mat-list-item> -->
        <mat-list-item *ngFor="let option of sortOptionsWithoutDefault" (click)="sortOption = option">
            <button mat-menu-item>
                <mat-icon>{{ getSortIcon(option) }}</mat-icon>
                <span>{{ sortService.getSortLabel(option) | translate }}</span>
            </button>
        </mat-list-item>
    </div>
</mat-menu>
