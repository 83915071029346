<div class="progress-grid-wrapper progress-snack-bar">
    <div classs="message">
        {{ message | translate }}
    </div>
    <div class="bar">
        <mat-progress-bar [mode]="mode" [value]="value"></mat-progress-bar>
    </div>
    <div class="action">
        <button
            mat-icon-button
            color="warn"
            matTooltip="{{ 'Cancel' | translate }}"
            (click)="snackBarRef.dismissWithAction()"
        >
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
