<div class="charts-wrapper" [style.height]="calcBarChartHeight()">
    <canvas
        baseChart
        class="chart-js-canvas"
        *ngIf="isReadyToShow"
        [data]="chartData"
        [labels]="labels"
        [options]="chartOptions"
        [type]="type"
        [legend]="legend"
    ></canvas>
</div>
