// THIS FILE IS GENERATED AUTOMATICALLY. DO NOT CHANGE IT MANUALLY.

import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const meetingSettingsDefaults: { [key: string]: any } = {
    enable_anonymous: false,
    export_csv_separator: `;`,
    export_csv_encoding: `utf-8`,
    export_pdf_pagenumber_alignment: `center`,
    export_pdf_fontsize: 10,
    export_pdf_pagesize: `A4`,
    export_pdf_line_height: 1.25,
    export_pdf_page_margin_left: 20,
    export_pdf_page_margin_top: 25,
    export_pdf_page_margin_right: 20,
    export_pdf_page_margin_bottom: 20,
    agenda_enable_numbering: true,
    agenda_numeral_system: `arabic`,
    agenda_item_creation: `default_no`,
    agenda_new_items_default_visibility: `internal`,
    agenda_show_internal_items_on_projector: false,
    agenda_show_subtitles: false,
    list_of_speakers_enable_point_of_order_speakers: true,
    list_of_speakers_enable_pro_contra_speech: false,
    list_of_speakers_can_set_contribution_self: false,
    list_of_speakers_present_users_only: false,
    list_of_speakers_speaker_note_for_everyone: true,
    list_of_speakers_initially_closed: false,
    list_of_speakers_show_first_contribution: false,
    list_of_speakers_couple_countdown: true,
    list_of_speakers_show_amount_of_speakers_on_slide: true,
    list_of_speakers_amount_last_on_projector: 0,
    list_of_speakers_amount_next_on_projector: -1,
    projector_countdown_warning_time: 0,
    projector_countdown_default_time: 60,
    motions_preamble: _(`The assembly may decide:`),
    motions_default_line_numbering: `outside`,
    motions_line_length: 85,
    motions_reason_required: false,
    motions_enable_text_on_projector: true,
    motions_enable_reason_on_projector: false,
    motions_enable_recommendation_on_projector: true,
    motions_show_referring_motions: true,
    motions_enable_sidebox_on_projector: false,
    motions_show_sequential_number: true,
    motions_recommendation_text_mode: `diff`,
    motions_default_sorting: `number`,
    motions_number_type: `per_category`,
    motions_number_min_digits: 2,
    motions_number_with_blank: false,
    motions_amendments_enabled: true,
    motions_statutes_enabled: false,
    motions_amendments_in_main_list: true,
    motions_amendments_prefix: _(`-Ä`),
    motions_amendments_text_mode: `paragraph`,
    motions_amendments_multiple_paragraphs: true,
    motions_amendments_of_amendments: false,
    motions_supporters_min_amount: 0,
    motions_block_slide_columns: 3,
    motion_poll_default_type: `pseudoanonymous`,
    motion_poll_default_onehundred_percent_base: `YNA`,
    motion_poll_ballot_paper_selection: `CUSTOM_NUMBER`,
    motion_poll_ballot_paper_number: 8,
    motion_poll_default_backend: `fast`,
    motions_export_title: _(`Motions`),
    motions_export_submitter_recommendation: true,
    motions_export_follow_recommendation: false,
    assignment_poll_default_method: `Y`,
    assignment_poll_default_type: `pseudoanonymous`,
    assignment_poll_default_onehundred_percent_base: `valid`,
    assignment_poll_sort_poll_result_by_votes: true,
    assignment_poll_add_candidates_to_list_of_speakers: false,
    assignment_poll_enable_max_votes_per_option: false,
    assignment_poll_default_backend: `fast`,
    assignment_poll_ballot_paper_selection: `CUSTOM_NUMBER`,
    assignment_poll_ballot_paper_number: 8,
    assignments_export_title: _(`Elections`),
    users_enable_presence_view: false,
    users_allow_self_set_present: true,
    users_enable_vote_weight: false,
    users_enable_vote_delegations: false,
    users_pdf_welcometitle: _(`Welcome to OpenSlides`),
    users_pdf_welcometext: _(`[Place for your welcome and help text.]`),
    users_pdf_wlan_encryption: `WPA`,
    users_pdf_url: `https://example.com`,
    users_email_sender: `OpenSlides`,
    users_email_subject: _(`OpenSlides access data`),
    users_email_body: `Dear {name},\n\nthis is your personal OpenSlides login:\n\n{url}\nUsername: {username}\nPassword: {password}\n\n\nThis email was generated automatically.`,
    conference_show: false,
    conference_los_restriction: true,
    conference_auto_connect: false,
    conference_open_microphone: false,
    conference_open_video: false,
    conference_auto_connect_next_speakers: 0,
    conference_enable_helpdesk: false,
    applause_enable: false,
    applause_show_level: false,
    applause_type: `applause-type-bar`,
    applause_min_amount: 1,
    applause_max_amount: 0,
    applause_timeout: 5
};
