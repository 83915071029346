<div *osPerms="permission.userCanSee">
    <div *ngIf="isViewingThis">
        <os-list
            [listObservable]="entitledUsersObservable"
            [filterProps]="filterPropsEntitledUsersTable"
            [fullScreen]="false"
        >
            <!-- Content -->
            <div *osScrollingTableCell="'user_id'; row as entry">
                <div *osScrollingTableCellLabel>{{ 'Participant' | translate }}</div>
                <div *ngIf="entry.user">
                    {{ entry.user.getShortName() }}
                    <div class="user-subtitle">
                        <!-- Level and number -->
                        <div *ngIf="entry.user.getLevelAndNumber()">
                            {{ entry.user.getLevelAndNumber() }}
                        </div>

                        <!-- Delegation -->
                        <div *ngIf="entry.vote_delegated_to_user_id">
                            <span>
                                ({{ 'represented by' | translate }} {{ entry.vote_delegated_to.getShortName().trim() }})
                            </span>
                        </div>
                    </div>
                </div>
                <i *ngIf="!entry.user">{{ 'Anonymous' | translate }}</i>
            </div>
            <div *osScrollingTableCell="'voted'; row as entry; config: { width: 24 }">
                <div *osScrollingTableCellLabel>{{ 'Voted' | translate }}</div>
                <mat-icon *ngIf="entry.voted">how_to_vote</mat-icon>
            </div>
        </os-list>
    </div>
</div>
<div class="no-can-see-users" *osPerms="permission.userCanSee; complement: true">
    <i>{{ 'You are not allowed to see all entitled users.' | translate }}</i>
</div>
