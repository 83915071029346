<div cdkDropList [cdkDropListDisabled]="!enable" (cdkDropListDropped)="drop($event)">
    <div class="line" *ngIf="!sortedItems.length">
        <span>{{ 'No data' | translate }}</span>
    </div>
    <div
        [ngClass]="isSelectedRow(i) ? 'backgroundColorSelected' : 'backgroundColorLight'"
        class="line margin-bottom-5"
        *ngFor="let item of sortedItems; let i = index"
        cdkDrag
        [cdkDragStartDelay]="dragDelay"
        (click)="onItemClick($event, i)"
        (cdkDragStarted)="dragStarted(i)"
    >
        <!-- drag handle -->
        <div class="drag-indicator" cdkDragHandle *ngIf="enable">
            <mat-icon>drag_indicator</mat-icon>
        </div>

        <!-- Count number -->
        <div *ngIf="count" class="count">
            <span>{{ i + 1 }}.</span>
        </div>

        <!-- Content -->
        <div class="content">
            <ng-template
                [ngTemplateOutlet]="templateRef"
                [ngTemplateOutletContext]="{ $implicit: item, index: i }"
            ></ng-template>
        </div>

        <div *cdkDragPlaceholder class="margin-bottom-5"></div>

        <!-- Drag prev -->
        <div class="line" *cdkDragPreview>
            <div class="spacer.left-10" *ngIf="multiSelectedIndex.length > 0">
                {{ multiSelectedIndex.length }}&nbsp;
                <span>{{ 'items selected' | translate }}</span>
            </div>
        </div>
    </div>
</div>
