<h2 mat-dialog-title>
    <span>{{ 'Project selection?' | translate }}</span>
</h2>
<div class="element-name" *ngIf="descriptor">
    {{ descriptor.getDialogTitle() }}
</div>

<mat-dialog-content *ngIf="projectors && projectors.length">
    <div class="content-wrapper">
        <div
            class="projectors"
            *ngFor="let projector of projectors"
            [ngClass]="isProjectedOn(projector) ? 'projected' : ''"
        >
            <mat-checkbox [checked]="isProjectorSelected(projector)" (change)="toggleProjector(projector)">
                {{ projector.name }}
            </mat-checkbox>
            <span *ngIf="isProjectedOn(projector)" class="right">
                <mat-icon matTooltip="{{ 'Is already projected' | translate }}" matTooltipPosition="above">
                    videocam
                </mat-icon>
            </span>
        </div>
    </div>

    <mat-divider *ngIf="options && options.length"></mat-divider>

    <div *ngIf="options && options.length">
        <div *ngFor="let option of options">
            <div *ngIf="isDecisionOption(option)" class="spacer-top-10 spacer-left-10">
                <mat-checkbox
                    [checked]="optionValues[option.key]"
                    (change)="optionValues[option.key] = !optionValues[option.key]"
                >
                    {{ option.displayName | translate }}
                </mat-checkbox>
            </div>
            <div *ngIf="isChoiceOption(option)">
                <h3>{{ option.displayName | translate }}</h3>
                <mat-radio-group [name]="option.key" [(ngModel)]="optionValues[option.key]">
                    <mat-radio-button *ngFor="let choice of option.choices" [value]="choice.value">
                        {{ choice.displayName | translate }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button osAutofocus (click)="onProject()" color="accent">{{ 'Project' | translate }}</button>
    <button mat-button (click)="onAddToPreview()">{{ 'Add to queue' | translate }}</button>
    <button mat-button (click)="onCancel()">{{ 'Cancel' | translate }}</button>
</mat-dialog-actions>
