<ng-container *ngIf="!swap && showIcon; then iconTemplate"></ng-container>
<span
    class="content-node"
    [ngClass]="{ 'single-line': noWrap, 'break-lines': !noWrap, 'content-swapped': swap, 'fill-space': !noFill }"
>
    <ng-content></ng-content>
    <ng-content select="[afterContent]"></ng-content>
</span>
<ng-container *ngIf="swap && showIcon; then iconTemplate"></ng-container>

<ng-template #iconTemplate>
    <mat-icon
        [inline]="inline"
        [matTooltip]="iconTooltip"
        [matTooltipPosition]="iconTooltipPosition"
        [ngClass]="{ pointer: iconAction, mirrored: mirrored }"
        [ngStyle]="{ transform: getRotation() }"
        (click)="iconClick()"
        [color]="color"
    >
        {{ icon }}
    </mat-icon>
</ng-template>
