<os-detail-view [collection]="COLLECTION" (idFound)="onIdFound($event)">
    <os-head-bar
        [nav]="false"
        [goBack]="true"
        [editMode]="isMobile && manualSortMode"
        (cancelEditEvent)="setManualSortMode(false)"
        (saveEvent)="onMobileSaveSorting()"
    >
        <!-- Title -->
        <div class="title-slot">
            <h2>
                <span *ngIf="!isCurrentListOfSpeakers">{{ 'List of speakers' | translate }}</span>
                <span *ngIf="isCurrentListOfSpeakers">{{ 'Current list of speakers' | translate }}</span>
            </h2>
        </div>
        <div class="menu-slot" *osPerms="[permission.listOfSpeakersCanManage, permission.projectorCanManage]">
            <button
                type="button"
                mat-icon-button
                matTooltip="{{ 'Re-add last speaker' | translate }}"
                (click)="readdLastSpeaker()"
                [disabled]="!canReaddLastSpeaker"
            >
                <mat-icon>undo</mat-icon>
            </button>
            <button type="button" mat-icon-button [matMenuTriggerFor]="speakerMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
    </os-head-bar>

    <os-list-of-speakers-content
        #content
        [listOfSpeakers]="viewListOfSpeakers"
        [sortMode]="manualSortMode"
        (isListOfSpeakersEmptyEvent)="isListOfSpeakersEmpty = $event"
        (canReaddLastSpeakerEvent)="canReaddLastSpeaker = $event"
    ></os-list-of-speakers-content>
</os-detail-view>

<mat-menu #speakerMenu="matMenu">
    <button *ngIf="isMobile" mat-menu-item (click)="setManualSortMode(true)">
        <mat-icon>sort</mat-icon>
        <span>{{ 'Sort' | translate }}</span>
    </button>

    <os-projector-button
        *ngIf="viewListOfSpeakers && projectors && projectors.length > 1"
        [object]="getClosSlide()"
        [menuItem]="true"
    >
        {{ 'Current list of speakers (as slide)' | translate }}
    </os-projector-button>

    <os-projector-button *ngIf="viewListOfSpeakers" [object]="viewListOfSpeakers" [menuItem]="true">
        {{ 'List of speakers' | translate }}
    </os-projector-button>

    <os-projector-button *ngIf="viewListOfSpeakers" [object]="viewListOfSpeakers.content_object!" [menuItem]="true">
        {{ getContentObjectProjectorButtonText() | translate }}
    </os-projector-button>

    <button mat-menu-item (click)="setLosClosed(!isListOfSpeakersClosed)">
        <ng-container *ngIf="isListOfSpeakersClosed">
            <mat-icon>lock_open</mat-icon>
            <span>{{ 'Open list of speakers' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="!isListOfSpeakersClosed">
            <mat-icon>lock</mat-icon>
            <span>{{ 'Close list of speakers' | translate }}</span>
        </ng-container>
    </button>

    <mat-divider></mat-divider>

    <button
        mat-menu-item
        (click)="clearPreviousSpeakerList()"
        [disabled]="isPreviousListOfSpeakersEmpty"
        class="red-warning-text"
    >
        <mat-icon>delete</mat-icon>
        <span>{{ 'Remove all previous speakers' | translate }}</span>
    </button>

    <button
        mat-menu-item
        (click)="clearNextSpeakerList()"
        [disabled]="isNextListOfSpeakersEmpty"
        class="red-warning-text"
    >
        <mat-icon>delete</mat-icon>
        <span>{{ 'Remove all next speakers' | translate }}</span>
    </button>

    <button mat-menu-item (click)="clearSpeakerList()" [disabled]="isListOfSpeakersEmpty" class="red-warning-text">
        <mat-icon>delete</mat-icon>
        <span>{{ 'Remove all speakers' | translate }}</span>
    </button>
</mat-menu>
