<ng-container *osPerms="[permission.listOfSpeakersCanSee, permission.listOfSpeakersCanBeSpeaker]; and: hasInitialized">
    <ng-container *ngIf="currentListOfSpeakersObservable | async as listOfSpeakers">
        <os-detail-view-navigator [model]="listOfSpeakers">
            <button
                *ngIf="!menuItem"
                type="button"
                mat-icon-button
                matTooltip="{{
                    (listOfSpeakers.closed ? 'The list of speakers is closed.' : 'List of speakers') | translate
                }}"
                [disabled]="disabled"
                class="anchor-button"
            >
                <mat-icon
                    [matBadge]="
                        canSee && listOfSpeakers.waitingSpeakerAmount > 0 ? listOfSpeakers.waitingSpeakerAmount : null
                    "
                    matBadgeColor="accent"
                >
                    {{ listOfSpeakers.closed ? 'voice_over_off' : 'record_voice_over' }}
                </mat-icon>
            </button>

            <button type="button" *ngIf="menuItem" mat-menu-item>
                <mat-icon>{{ listOfSpeakers.closed ? 'voice_over_off' : 'record_voice_over' }}</mat-icon>
                <span>{{ 'List of speakers' | translate }}</span>
                <span>&nbsp;</span>
                <mat-basic-chip
                    disableRipple
                    class="lightblue"
                    *ngIf="canSee && listOfSpeakers.waitingSpeakerAmount > 0"
                >
                    <span>{{ listOfSpeakers.waitingSpeakerAmount }}</span>
                </mat-basic-chip>
            </button>
        </os-detail-view-navigator>
    </ng-container>
</ng-container>
