<mat-select
    #matSelect
    [formControl]="contentForm"
    [multiple]="multiple"
    [panelClass]="{ 'os-search-selector-wider': wider, 'os-search-selector-panel': true }"
    [errorStateMatcher]="errorStateMatcher"
    (openedChange)="onOpenChanged($event)"
>
    <!-- Custom display of selected items -->
    <mat-select-trigger *ngIf="multiple">
        <ng-container *ngIf="selectedItems?.length">
            <span *ngFor="let item of selectedItems; let last = last" class="inline-flex">
                {{ item.getTitle() | translate }} {{ getItemAdditionalInfoFn(item) }}
                <span *ngIf="!last">,&nbsp;</span>
            </span>
        </ng-container>
    </mat-select-trigger>

    <div class="os-search-selector--input-wrapper" *ngIf="matSelect.panelOpen">
        <input
            matInput
            osAutofocus
            class="background-card"
            [formControl]="searchValueForm"
            placeholder="{{ 'Search' | translate }}"
            (keydown)="onSearchKeydown($event)"
        />
    </div>
    <ng-container *ngIf="multiple && showChips">
        <div #chipPlaceholder class="os-search-selector--chip-wrapper">
            <div class="os-search-selector--chip-container flex-vertical-center">
                <mat-chip-list class="chip-list" [selectable]="false">
                    <mat-chip
                        *ngFor="let item of selectedItems"
                        class="one-line"
                        [removable]="true"
                        (removed)="onChipRemove(item.id)"
                        [disableRipple]="true"
                    >
                        <span class="one-line">
                            {{ item.getTitle() | translate }}
                        </span>
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                </mat-chip-list>
            </div>
            <div class="os-search-selector--chip-placeholder"></div>
        </div>
    </ng-container>
    <ng-container *ngIf="showNotFoundButton">
        <button class="os-not-found-button" mat-button (click)="onNotFoundClick()">
            <ng-container *ngTemplateOutlet="notFoundTemplate"></ng-container>
        </button>
    </ng-container>
    <div #scrollViewport [ngStyle]="{ height: panelHeight + 'px' }" class="vscroll-viewport">
        <mat-option
            *ngIf="(filteredItemsObservable | async)?.length === 0"
            class="os-search-selector--no-options"
            disabled
        >
            {{ noOptionsFoundLabel | translate }}
        </mat-option>
        <mat-option
            #currentOption
            *ngFor="let selectableItem of filteredItemsObservable | async"
            [value]="selectableItem.id"
            [disabled]="disableOptionWhenFn(selectableItem) || selectableItem.disabled"
            [matTooltip]="tooltipFn(selectableItem, currentOption)"
            matTooltipPosition="left"
            (onSelectionChange)="onSelectionChange(selectableItem, $event)"
            (click)="keepOpen ? matSelect.open() : null"
        >
            {{ selectableItem.getTitle() | translate }} {{ getItemAdditionalInfoFn(selectableItem) }}
        </mat-option>
    </div>
</mat-select>
