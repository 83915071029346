<form [formGroup]="changePasswordForm">
    <mat-form-field>
        <mat-label>{{ 'Old password' | translate }}</mat-label>
        <input
            [type]="hideOldPassword ? 'password' : 'text'"
            matInput
            required
            formControlName="oldPassword"
            #oldPassword
        />
        <button
            matSuffix
            mat-button
            mat-icon-button
            tabindex="-1"
            type="button"
            (click)="hideOldPassword = !hideOldPassword"
            [disabled]="!oldPassword.value"
        >
            <mat-icon>{{ hideOldPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ 'New password' | translate }}</mat-label>
        <input
            [type]="hideNewPassword ? 'password' : 'text'"
            matInput
            required
            formControlName="newPassword"
            #newPassword
        />
        <button
            matSuffix
            mat-button
            mat-icon-button
            tabindex="-1"
            type="button"
            (click)="hideNewPassword = !hideNewPassword"
            [disabled]="!newPassword.value"
        >
            <mat-icon>{{ hideNewPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ 'Confirm new password' | translate }}</mat-label>
        <input
            [type]="hideConfirmPassword ? 'password' : 'text'"
            matInput
            required
            formControlName="confirmPassword"
            #confirmPassword
        />
        <button
            matSuffix
            mat-button
            mat-icon-button
            tabindex="-1"
            type="button"
            (click)="hideConfirmPassword = !hideConfirmPassword"
            [disabled]="!confirmPassword.value"
        >
            <mat-icon>{{ hideConfirmPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
        <mat-error *ngIf="changePasswordForm.get('confirmPassword')?.hasError('passwordMismatch')">
            {{ 'Passwords do not match' | translate }}
        </mat-error>
    </mat-form-field>
</form>
