<os-view-list
    [alsoFilterByProperties]="alsoFilterByProperties"
    [alwaysShowMenu]="alwaysShowMenu"
    [filterService]="filterService"
    [filterProps]="filterProps"
    [hiddenColumns]="hiddenColumns"
    [listObservable]="listObservable"
    [listObservableProvider]="listObservableProvider"
    [multiSelect]="multiSelect"
    [searchFieldValue]="inputValue"
    [searchService]="searchService"
    [selectedRows]="selectedRows"
    [showFilterBar]="showFilterBar"
    [showMenu]="showMenu"
    [sortService]="sortService"
    [vScrollFixed]="vScrollFixed"
    (selectedRowsChange)="selectedRowsChange.emit($event)"
    (searchFilterUpdated)="searchFilterUpdated.emit($event)"
>
    <!-- Projector column -->
    <div
        *osScrollingTableCell="
            'projector';
            row as viewModel;
            config: { width: projectorButtonColumnWidth, position: START_POSITION }
        "
        class="fill ngrid-lg"
    >
        <os-projector-button
            *osPerms="permission.projectorCanManage"
            class="projector-button"
            [object]="viewModel"
            (changeEvent)="viewUpdateEvent()"
        ></os-projector-button>
        <!-- Projector indicator -->
        <div class="projector-button" *osPerms="permission.projectorCanManage; complement: true">
            <mat-icon
                color="accent"
                *ngIf="isProjectedFn(viewModel)"
                matTooltip="{{ 'Currently projected' | translate }}"
            >
                videocam
            </mat-icon>
        </div>
    </div>

    <!-- Speaker -->
    <div
        *osScrollingTableCell="
            'speaker';
            row as viewModel;
            rowContext as rowContext;
            config: { width: 60, position: END_POSITION }
        "
        class="fill"
    >
        <os-speaker-button [object]="_getSpeakerButtonObject(viewModel)" [disabled]="multiSelect"></os-speaker-button>
    </div>
</os-view-list>
