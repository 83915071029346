<ng-container *osPerms="permission.agendaItemCanManage">
    <ng-container>
        <div [formGroup]="form">
            <p>
                <mat-checkbox formControlName="agenda_create">
                    <span>{{ 'Add to agenda' | translate }}</span>
                </mat-checkbox>
            </p>
        </div>

        <ng-container *ngIf="!!checkbox?.value">
            <!-- Visibility -->
            <div>
                <mat-form-field [formGroup]="form">
                    <mat-select formControlName="agenda_type" placeholder="{{ 'Agenda visibility' | translate }}">
                        <mat-option *ngFor="let type of itemVisibilityChoices" [value]="type.key">
                            <span>{{ type.name | translate }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- Parent item -->
            <div *ngIf="hasItemsObservable | async" [formGroup]="form">
                <mat-form-field>
                    <os-list-search-selector
                        formControlName="agenda_parent_id"
                        [multiple]="false"
                        [includeNone]="true"
                        placeholder="{{ 'Parent agenda item' | translate }}"
                        [inputListValues]="itemObserver"
                    ></os-list-search-selector>
                </mat-form-field>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
