<mat-drawer-container class="list-view-frame">
    <div class="list-view-table-wrapper">
        <os-sort-filter-bar
            *ngIf="showFilterBar"
            class="sort-filter-bar"
            [totalCount]="totalCountObservable | async"
            [filterCount]="currentCountObservable | async"
            [filterService]="filterService!"
            [sortService]="sortService!"
            [searchService]="searchService!"
            [searchFieldInput]="searchFieldValue"
            (searchFieldChanged)="searchFilterUpdated.emit($event)"
        >
            <div class="extra-info-slot">
                <ng-content select=".filter-bar-info-slot"></ng-content>
            </div>
        </os-sort-filter-bar>

        <os-scrolling-table
            [dataSource]="dataListObservable"
            [fullDataSource]="dataFullListObservable"
            [rowHeight]="vScrollFixed"
            [tableHeight]="fullScreen ? undefined : '50vh'"
            [hiddenColumns]="hiddenColumns"
            [selectionMode]="multiSelect"
            (selectionChanged)="selectedRowsChange.emit($event.selectedRows)"
        >
            <div *osScrollingTableNoData class="no-data-placeholder subtitle">{{ 'No data' | translate }}</div>
        </os-scrolling-table>
    </div>
</mat-drawer-container>
