<div class="content-container">
    <ng-container *ngIf="isEditing">
        <ng-container *ngIf="useMatcard">
            <mat-card class="os-card">
                <ng-container *ngTemplateOutlet="editTemplate; context: { form: personalInfoForm }"></ng-container>
            </mat-card>
            <mat-card *ngIf="editView" class="os-form-card margin-bottom-60">
                <ng-container *ngTemplateOutlet="editView; context: { form: personalInfoForm }"></ng-container>
            </mat-card>
        </ng-container>
        <ng-container *ngIf="!useMatcard">
            <ng-container *ngTemplateOutlet="editTemplate; context: { form: personalInfoForm }"></ng-container>
            <ng-container
                *ngIf="editView"
                [ngTemplateOutlet]="editView"
                [ngTemplateOutletContext]="{ form: personalInfoForm }"
            ></ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!isEditing && user">
        <ng-container *ngIf="useMatcard">
            <mat-card class="os-card">
                <ng-container *ngTemplateOutlet="showTemplate"></ng-container>
            </mat-card>
            <ng-container *ngIf="showView">
                <mat-card
                    [ngClass]="{ 'os-card': true, 'margin-bottom-60': isLast }"
                    *ngFor="let showViewElement of showView; last as isLast"
                >
                    <ng-container *ngTemplateOutlet="showViewElement; context: { user: user }"></ng-container>
                </mat-card>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!useMatcard">
            <ng-container *ngTemplateOutlet="showTemplate"></ng-container>
            <ng-container *ngIf="showView">
                <ng-container
                    *ngFor="let showViewElement of showView"
                    [ngTemplateOutlet]="showViewElement"
                    [ngTemplateOutletContext]="{ user: user }"
                ></ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<ng-template #editTemplate>
    <form [formGroup]="personalInfoForm">
        <h2>{{ 'Personal information' | translate }}</h2>
        <div class="personal-info-area">
            <!-- Title -->
            <mat-form-field class="form16 force-min-width">
                <input type="text" matInput placeholder="{{ 'Title' | translate }}" formControlName="title" />
            </mat-form-field>
            <!-- First name -->
            <mat-form-field class="form37 force-min-width">
                <input
                    type="text"
                    osAutofocus
                    matInput
                    placeholder="{{ 'Given name' | translate }}"
                    formControlName="first_name"
                />
            </mat-form-field>

            <!-- Last name -->
            <mat-form-field class="form37 force-min-width">
                <input type="text" matInput placeholder="{{ 'Surname' | translate }}" formControlName="last_name" />
            </mat-form-field>
        </div>

        <div class="personal-info-area">
            <!-- E-Mail -->
            <mat-form-field class="width-58p force-min-width">
                <input
                    type="email"
                    matInput
                    autocomplete="off"
                    placeholder="{{ 'Email' | translate }}"
                    name="email"
                    formControlName="email"
                />
                <mat-error *ngIf="personalInfoForm.get('email')?.hasError('email')">
                    {{ 'Please enter a valid email address' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- Pronoun -->
            <mat-form-field class="width-16p force-min-width">
                <input type="text" matInput placeholder="{{ 'Pronoun' | translate }}" formControlName="pronoun" />
                <mat-error>{{ 'max. 32 characters allowed' | translate }}</mat-error>
            </mat-form-field>

            <!-- Gender -->
            <mat-form-field class="width-16p force-min-width">
                <mat-select placeholder="{{ 'Gender' | translate }}" formControlName="gender">
                    <mat-option [value]="null">-</mat-option>
                    <mat-option *ngFor="let gender of genders" [value]="gender">
                        {{ gender | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- username -->
        <div *ngIf="isAllowed('seePersonal')">
            <mat-form-field class="full-width">
                <input type="text" matInput placeholder="{{ 'Username' | translate }}" formControlName="username" />
                <button
                    *ngIf="isNewUser"
                    matSuffix
                    type="button"
                    mat-button
                    mat-icon-button
                    [matTooltip]="'Leave blank to automatically generate the username.' | translate"
                    matTooltipPosition="above"
                >
                    <mat-icon>help</mat-icon>
                </button>
                <mat-error>{{ 'You have to enter at least one character' | translate }}</mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="isAllowed('manage') && !user?.saml_id">
            <!-- tech id -->
            <mat-form-field class="full-width">
                <input
                    matInput
                    autocomplete="off"
                    placeholder="{{ 'Technische ID' }}"
                    formControlName="tech_id"
                />
            </mat-form-field>
            <!-- member id -->
            <mat-form-field class="full-width">
                <input
                    matInput
                    autocomplete="off"
                    placeholder="{{ 'Mitgliedsnummer' }}"
                    formControlName="member_id"
                />
            </mat-form-field>
            <!-- Initial Password -->
            <mat-form-field class="full-width">
                <input
                    matInput
                    autocomplete="off"
                    placeholder="{{ 'Initial password' | translate }}"
                    formControlName="default_password"
                />
                <button
                    *ngIf="isNewUser && generatePasswordFn"
                    matSuffix
                    type="button"
                    mat-button
                    mat-icon-button
                    [disabled]="!generatePasswordFn"
                    (click)="setRandomPassword()"
                    [matTooltip]="'Generate password' | translate"
                >
                    <mat-icon>autorenew</mat-icon>
                </button>
                <button
                    *ngIf="isNewUser"
                    matSuffix
                    type="button"
                    mat-button
                    mat-icon-button
                    [matTooltip]="'Leave blank to automatically generate the password.' | translate"
                >
                    <mat-icon>help</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div *ngIf="isAllowed('seeName')">
            <mat-checkbox formControlName="is_active" *ngIf="!isSelf">{{ 'active' | translate }}</mat-checkbox>
            <mat-checkbox formControlName="is_physical_person">{{ 'natural person' | translate }}</mat-checkbox>
        </div>
    </form>
</ng-template>

<ng-template #showTemplate>
    <h2>{{ 'Personal information' | translate }}</h2>
    <!-- User name -->
    <ng-container *ngIf="user">
        <div *ngIf="isAllowed('seeName')">
            <h4>{{ 'Name' | translate }}</h4>
            <span class="state-icons">
                <span>{{ user.short_name }}</span>
                <ng-container *ngIf="user.pronoun">
                    &nbsp;
                    <span>({{ user.pronoun }})</span>
                </ng-container>
                <mat-icon *ngIf="!user.is_active && isAllowed('manage')" matTooltip="{{ 'Inactive' | translate }}">
                    block
                </mat-icon>
                <mat-icon
                    *ngIf="!user.is_physical_person && isAllowed('manage')"
                    matTooltip="{{ 'Is no natural person' | translate }}"
                >
                    account_balance
                </mat-icon>
                <mat-icon
                    *ngIf="user.hasSamlId && isAllowed('manage')"
                    matTooltip="{{ 'Has SSO identification' | translate }}"
                >
                    fingerprint
                </mat-icon>
            </span>
        </div>

        <!-- Mail -->
        <div *ngIf="isAllowed('seePersonal')">
            <div *ngIf="user.email">
                <h4>{{ 'Email' | translate }}</h4>
                <span>{{ user.email }}</span>
            </div>
        </div>

        <!-- Gender -->
        <div *ngIf="user.gender">
            <h4>{{ 'Gender' | translate }}</h4>
            <span>{{ user.gender | translate }}</span>
        </div>

        <div *ngIf="isAllowed('manage')">
            <!-- Username -->
            <div *ngIf="user.username">
                <h4>{{ 'Username' | translate }}</h4>
                <span>{{ user.username }}</span>
            </div>

            <!-- Initial Password -->
            <div *ngIf="user.default_password && !user.saml_id">
                <h4>{{ 'Initial password' | translate }}</h4>
                <span>{{ user.default_password }}</span>
            </div>
            <!-- tech id -->
            <div *ngIf="user.tech_id">
                <h4>{{ 'Technische ID' }}</h4>
                <span>{{ user.tech_id }}</span>
            </div>
            <!-- member id -->
            <div *ngIf="user.member_id">
                <h4>{{ 'Mitgliedsnummer' }}</h4>
                <span>{{ user.member_id }}</span>
            </div>

            <!-- SAML id -->
            <div *ngIf="user.saml_id">
                <h4>{{ 'SSO Identification' | translate }}</h4>
                <span>{{ user.saml_id }}</span>
            </div>
        </div>
    </ng-container>
</ng-template>
