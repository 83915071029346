<mat-card class="os-card">
    <div *ngIf="!canManage && !isOwnPage">
        <!-- no Admin, cannot Manage (a.k.a Attack Prevention) -->
        <span>{{ 'You are not supposed to be here...' | translate }}</span>
    </div>
    <div *ngIf="canManage && !isOwnPage">
        <!-- can Manage, but not own Page (a.k.a. Admin) -->
        <div *ngIf="user">
            <h1>
                <span>{{ 'Change password for' | translate }}</span>
                {{ user.full_name }}
            </h1>
            <os-icon-container icon="warning" size="large">
                <span>{{ 'You override the personally set password!' | translate }}</span>
            </os-icon-container>
        </div>
        <br />
        <form [formGroup]="adminPasswordForm" (keydown)="onKeyDown($event)">
            <mat-form-field>
                <input
                    [type]="hidePassword ? 'password' : 'text'"
                    matInput
                    formControlName="newPassword"
                    placeholder="{{ 'New password' | translate }}"
                    required
                />
                <mat-icon
                    class="pointer"
                    matSuffix
                    mat-icon-button
                    matTooltip="{{ hidePassword ? ('Show password' | translate) : ('Hide password' | translate) }}"
                    (click)="hidePassword = !hidePassword"
                >
                    {{ hidePassword ? 'visibility' : 'visibility_off' }}
                </mat-icon>
                <mat-icon
                    class="pointer spacer-left-10"
                    matSuffix
                    mat-icon-button
                    matTooltip="{{ 'Generate password' | translate }}"
                    (click)="generatePassword()"
                >
                    autorenew
                </mat-icon>
            </mat-form-field>
        </form>
        <br />
        <div *ngIf="user">
            <span>{{ 'Initial password' | translate }}</span>
            : {{ user.default_password }}
            <br />
            <span>{{ 'Username' | translate }}</span>
            : {{ user.username }}
        </div>
    </div>

    <div *ngIf="isOwnPage" (keydown)="onKeyDown($event)">
        <!-- can not Manage, but own Page (a.k.a. User) -->
        <os-password-form
            (validEvent)="isUserPasswordValid = $event"
            (changeEvent)="userPasswordForm = $event"
        ></os-password-form>
    </div>
</mat-card>
