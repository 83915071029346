<os-view-list
    [alsoFilterByProperties]="alsoFilterByProperties"
    [alwaysShowMenu]="alwaysShowMenu"
    [filterService]="filterService"
    [filterProps]="filterProps"
    [hiddenColumns]="hiddenColumns"
    [listObservable]="listObservable"
    [listObservableProvider]="listObservableProvider"
    [multiSelect]="multiSelect"
    [searchFieldValue]="inputValue"
    [searchService]="searchService"
    [selectedRows]="selectedRows"
    [showFilterBar]="showFilterBar"
    [showMenu]="showMenu"
    [sortService]="sortService"
    [vScrollFixed]="vScrollFixed"
    [fullScreen]="fullScreen"
    (selectedRowsChange)="selectedRowsChange.emit($event)"
    (searchFilterUpdated)="searchFilterUpdated.emit($event)"
></os-view-list>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
