<h1 mat-dialog-title>
    <span>{{ 'Secret voting can not be guaranteed' | translate }}</span>
</h1>

<div mat-dialog-content>
    <span>
        {{
            'During non-nominal voting OpenSlides does NOT store the individual user ID of the voter. This in no way means
        that a non-nominal vote is completely anonymous and secure. The votes cannot track their individual votes after
        the data has been submitted. The validity of the data cannot always be guaranteed.' | translate
        }}
    </span>
</div>

<div mat-dialog-actions>
    <button type="button" mat-button [mat-dialog-close]="null">
        <span>{{ 'I know the risk' | translate }}</span>
    </button>
</div>
